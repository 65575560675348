@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('./fonts/Raleway/Raleway-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

body {
    margin: 0;
    background: rgb(19, 28, 46);
    color: white;
    font-family: 'Raleway',
      -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h1 {
    text-transform: uppercase;
}

button {
    cursor: pointer;
}

figure {
    margin: 0;
    margin-bottom: 20px;
    >img {
        width: 100%;
        height: auto;
    }
}