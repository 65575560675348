.bp5-button .bp5-icon { color: inherit !important; }

.bp5-form-group.bp5-intent-warning .bp5-label .bp5-text-muted {
    color: #935610;
}

.bp5-form-group.bp5-intent-danger .bp5-label .bp5-text-muted {
    color: #ac2f33;
}

.bp5-panel-stack2 {
    flex: 1;

    >.bp5-panel-stack-view {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        >:not(.bp5-panel-stack-header) {
            flex: 1;
            min-height: 0;
            min-width: 0;
        }
    }
}

.bp5-tabs {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    >.bp5-tab-panel {
        margin-top: 0 !important;
        flex: 1;
        min-height: 0;
        min-width: 0;
    }
}

:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
    -moz-outline-radius: 0 !important;
}

a, a:hover {
    color: inherit;
    text-decoration: inherit;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}

.bp5-button.port-stephens-afc-button {
    padding: 10px 20px;
    background-color: transparent;
    color: white;
    font-weight: bold;
    box-shadow: none !important;
    border: 2px solid white;
    border-radius: 8px;
    cursor: pointer;
    transition: 200ms;
    text-wrap: nowrap;

    &:hover {
        background-color: white;
        color: rgb(19, 28, 46);
    }
}

.bp5-button.register-button {
    padding: 10px 20px;
    margin-left: 10px;
    background-color: transparent;
    color: white;
    font-weight: bold;
    box-shadow: none !important;
    border: 2px solid white;
    border-radius: 8px;
    cursor: pointer;
    transition: 200ms;
    text-wrap: nowrap;

    &:hover {
        background-color: white;
        color: rgb(19, 28, 46);
    }
}

.react-photo-gallery--gallery>div>img {
    transition: ease 0.2s;
    &:hover { transform: scale(0.98); }
}
