.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: rgb(34, 30, 31);
    color: white;
    min-height: 80px;
    user-select: none;

    .navbar-container {
        align-items: center;
        display: flex;
        max-width: 1200px;
        padding: 0 32px;
        margin: auto;
    }

    .navbar-group {
        align-items: center;
        display: flex;
        height: 80px;

        &:nth-child(1) {
            flex-shrink: 0;
        }

        &.navbar-buttons {
            min-width: 440px;
            flex: 1;

            .bp5-overflow-list {
                flex: 1;
                justify-content: flex-end;
            }

            .more-button {
                flex-shrink: 0;
            }
        }

        .bp5-button.shop-button {
            flex-direction: column;
            gap: 2px;
            >.bp5-icon { margin-right: 0; }
            >.bp5-button-text { font-size: 12px;}
        }
    }

    .navbar-logo {
        filter: drop-shadow(0 0 6px rgba(255, 255, 255, 1));
        height: 60%;
    }

    .navbar-heading {
        font-size: 16px;
        margin: 0 16px;
        flex-shrink: 0;
    }

    .navbar-divider {
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        height: 60%;
        margin: 0 10px;
    }

    .bp5-button.navbar-link {
        color: white;
        font-size: 16px;
        transition: 200ms;

        &:hover {
            color: rgb(115, 204, 218);
            background: none;
        }
    }
}
.navbar .navbar-container {
    flex-wrap: wrap;
}
.more-popover.bp5-popover {
    .bp5-popover-arrow-fill { fill: rgb(48, 44, 45); }

    .bp5-popover-content{
        background-color: rgb(48, 44, 45);

        .bp5-menu {
            background-color: rgb(48, 44, 45);
            color: white;

            .bp5-menu-item.navbar-link {
                color: white;
                font-size: 16px;
                transition: 200ms;

                svg {
                    transition: 200ms;
                    fill: white;
                }

                &:hover {
                    color: rgb(115, 204, 218);
                    background: none;
                    svg { fill: rgb(115, 204, 218); }
                }
            }
        }
    }
}

.page-content {
    padding-bottom: 80px;
    position: relative;
    min-height: 100vh;

    h1 { margin-bottom: 4px; }
    p { margin-bottom: 16px; }
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background-color: rgb(34, 30, 31);
    color: white;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;

    .footer-buttons {
        user-select: none;
        align-items: center;
        gap: 16px;
        justify-content: center;
        display: flex;

        .bp5-button {
            color: white;
            font-size: 16px;
            transition: 200ms;
    
            svg {
                transition: 200ms;
                fill: white;
                height: 32px;
                width: 32px;
            }
    
            &:hover {
                color: rgb(115, 204, 218);
                background: none;
                svg { fill: rgb(115, 204, 218); }
            }
        }
    }
}
