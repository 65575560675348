.our-coaches-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >.our-coaches-cards {
        display: grid;
        grid-template-columns: repeat( auto-fit, 250px );
        max-width: 100%;
        gap: 24px;
    
        .coach-card {
            aspect-ratio: 7/14;
            background-color: rgb(34, 30, 31);
            overflow: hidden;
            display: flex;
            flex-direction: column;
    
            >.coach-card-content {
                position: relative;
                padding: 24px 12px 8px 12px;
    
                >.coach-card-name-container {
                    position: absolute;
                    transform: translateY(-50% - 9/2);
                    top: 0;
                    width: calc(100% - 24px);
    
                    >.coach-card-name {
                        padding: 6px 12px;
                        margin: auto;
                        width: 60%;
                        font-size: 18px;
                        font-weight: bold;
                        background-color: white;
                        color: black;
                        text-align: center;
                        border: 3px solid black;
                        border-radius: 8px 0 8px 0;
                    }
                }
            }
        }
    }
}