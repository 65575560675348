.banner {
    height: 240px;
    position: relative;
    user-select: none;

    .banner-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .banner-text {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        text-transform: uppercase;
        padding: 0 20px;
        text-shadow: 3px 4px rgb(0, 6, 10);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 6px;

        background: linear-gradient(to right, rgba(0, 20, 35, 0.9), rgba(0, 20, 35, 0.7) 50%, transparent);

        h1 { font-weight: 800; }
        h2 { font-weight: bold; }
    }
}

.page-slide {
    position: relative;
    height: 640px;
    overflow: hidden;
    max-width: 1280px;
    margin: auto;
    user-select: none;

    .blurb {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 280px;
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5));
        padding: 12px 24px;

        h2 {
            font-size: 26px;
            font-weight: 800;
            text-shadow: 2px 1px black;
            letter-spacing: 0.05rem;
        }

        .blurb-divider {
            width: 100%;
            height: 2px;
            background-color: white;
            box-shadow: 2px 1px 0 black;
            margin-bottom: 12px;
        }
        
        p {
            font-size: 14px;
            line-height: 1.2;
            font-weight: 600;
            text-shadow: 2px 1px black;
        }

        .bp5-button {
            font-weight: bold;
            margin-top: 8px;
            margin-bottom: 4px;
            padding: 8px 13px;
            font-size: 16px;
        }
    }
}

.carousel { background-color: rgb(34, 30, 31); }
.carousel .page-slide .slide,
.carousel .page-slide {
    text-align: left;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.carousel .sponsor {
    display: block;
    width: 100%;
    height: 100%;
}

.card {
    h2 {
        text-decoration: underline;
        font-size: 26px;
        font-weight: 800;
        text-shadow: 2px 1px black;
        letter-spacing: 0.05rem;
    }

    background-color: rgb(20, 64, 73);
    padding: 20px 28px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.cards {
    display: flex;
    flex-direction: row;
    gap: 28px;
    padding: 20px 28px;
    >* { flex: 1;}
}

#map {
    height: 360px;
    width: 100%;
}

.leaflet-control-button {
    display: flex !important;
    justify-content: center;
    align-items: center;
}