.profile-card {
    display: flex;
    gap: 20px;
    
    margin-bottom: 16px;

    .profile-card-image {
        flex: 0 0 200px;
        height: 200px;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .profile-card-content {
        flex: 1;
        min-width: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .profile-card-name {
            font-size: 24px;
            font-weight: bold;
        }

        .profile-card-title {
            font-size: 18px;
            font-weight: 600;
        }

        .profile-card-bio {
            font-size: 16px;
        }
    }
}