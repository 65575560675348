.divider,
.mini-divider {
    >.divider-line {
        &.divider-line-1 { background-color: rgb(115, 204, 218); }
        &.divider-line-2 { background-color: rgb(34, 30, 31); }
        &.divider-line-3 { background-color: white; }
    }
}

.divider {
    >.divider-line { height: 12px; }
}

.mini-divider {
    >.divider-line { height: 3px; }
}

.section-container {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;

    .section-container-inner {
        background: rgb(19, 28, 46);
        max-width: 1080px;
        min-height: calc(100vh - 160px);
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        gap: 20px
    }
}

@media (min-width: 960px) {
    .section-container {
        padding: 0 180px;
    }
}

.page-heading {
    margin: 60px 60px 40px 60px;
    h1 {
        font-size: 64px;
        text-transform: none;
    }

    >.page-heading-buttons-container {
        margin-top: 20px;

        >.page-heading-buttons {
            width: fit-content;
            display: flex;
            flex-wrap: wrap;
            gap: 14px;
        }
    }
}

.page-section {
    margin: 40px 60px 60px 60px;

    p {
        margin-bottom: 10px;
        a , a:hover {
            color: rgb(115, 204, 218);
            text-decoration: underline;
        }    
    }

    h2 {
        font-size: 32px;
        text-transform: none;
        margin-bottom: 12px;
        &:not(:first-child) { margin-top: 20px; }
    }
    
    h3 {
        font-size: 18px;
        &:not(:first-child) { margin-top: 20px; }
    }

    h4 {
        font-size: 14px;
        &:not(:first-child) { margin-top: 12px; }
    }

    table, th, td {
        border: 1px solid white;
    }

    table {
        border-collapse: collapse;
        margin-bottom: 10px;
        width: 100%;
    }

    th, td {
        vertical-align: top;
        text-align: left;
        padding: 4px 8px;
    }
}

.training-groups-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >.training-groups-cards {
        display: grid;
        grid-template-columns: repeat( auto-fit, 170px );
        max-width: 100%;
        gap: 24px;
    
        >.training-group-card {
            aspect-ratio: 5/6;
            background-color: rgb(34, 30, 31);
            border-radius: 6px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            position: relative;
            top: 0;
            transition: ease 0.2s;
            box-shadow: 4px 8px rgba(0, 0, 0, 0.5);
    
            .training-group-card-title {
                text-align: center;
                padding: 8px 0;
                font-size: 18px;
                background-color: rgb(20, 64, 73);
            }
    
            .training-group-card-information {
                padding: 8px;
                font-size: 14px;
                background-color: rgb(34, 30, 31); 
            }
    
            .training-group-card-fixture-prompt {
                position: absolute;
                width: 100%;
                padding: 8px;
                bottom: 0;
                transform: translateY(0);
                text-align: center;
                font-weight: bold;
                font-size: 16px;
            }
    
            &:not(.disabled):hover {
                top: -4px;
                box-shadow: 6px 12px rgba(0, 0, 0, 0.5);
            }

            &.disabled {
                .training-group-card-fixture-prompt {
                    color: rgb(136, 136, 136);
                }
            }
        }
    }    
}
